import Matomo from '@package/components/theme/Matomo/Matomo';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

export default function applyConfig(config) {
  config.settings = {
    ...config.settings,

    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: Matomo,
      },
    ],

    // Enable multilingual
    isMultilingual: true,
    supportedLanguages: ['fi', 'en'],
    defaultLanguage: 'fi',
  };

  return config;
}
